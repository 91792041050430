import React from 'react';
import Container from 'components/Container';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as Styles from './RoadmapTab.styles';
import { TabTitle } from './Styles';
import Disclaimer from '../components/Disclaimer';
import { useLocalise, useStringToArrayRows } from 'hooks/use-localise';

type RoadMap = {
  id?: string;
  title: string;
  subtitle: string;
  status: string;
  content: string;
  milestoneColor: string;
};

const content = {
  // This is a translationKey where data comes from translation.json
  steps: ['roadmapContent'] as unknown as RoadMap[],
};

const RoadmapTab = (): JSX.Element => {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  // Establishing localise with viewname and sectionName
  const { localise } = useLocalise('Properties', 'Roadmap');

  const localisedRoadMap = useStringToArrayRows(localise, content, {
    rowName: 'steps',
  })['steps'] as RoadMap[];

  const isBlue = (text: string) => text === 'UPCOMING';
  return (
    <Container
      display="flex"
      sx={{
        flexDirection: { md: 'row', sm: 'column', xs: 'column' },
        paddingTop: { md: '14px', sm: '14px', xs: '14px' },
        margin: 0,
      }}
    >
      <Styles.ContentBox>
        <TabTitle>{localise('title')}</TabTitle>

        {upMd && <Styles.ContentContainer />}

        {localisedRoadMap.map((card, index) => (
          <Styles.ContentContainer
            key={card.id}
            sx={{ flexDirection: index % 2 === 0 ? 'row' : 'row-reverse' }}
          >
            <Styles.InfoBox
              sx={{ textAlign: index % 2 === 0 ? 'right' : 'left' }}
            >
              <Styles.Title
                component="h1"
                color={isBlue(card.title) ? 'primary' : 'text.primary'}
              >
                {card.title}
              </Styles.Title>
              <Styles.Subtitle component="h2" color="text.primary">
                <div>
                  {card.subtitle + ' '}
                  <span style={{ color: '#3F826D' }}>{card.status}</span>
                </div>
              </Styles.Subtitle>
              <Styles.InfoText component="p">{card.content}</Styles.InfoText>
            </Styles.InfoBox>
            <Styles.MidBox>
              <Styles.CircleMilestone
                sx={{ backgroundColor: card.milestoneColor }}
              />
              <Styles.DashedLine />
            </Styles.MidBox>
            <Styles.SideBox />
          </Styles.ContentContainer>
        ))}
        <Disclaimer />
      </Styles.ContentBox>
    </Container>
  );
};

export default RoadmapTab;
