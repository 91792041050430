import React from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { StyledPropsFix } from 'types';

export const MainTitle = styled(Typography)`
  ${() => `
  font-family: Poppins SemiBold;
  font-size: 35px;
  text-align: left;
  `}
` as StyledPropsFix;

export const Title = styled(Typography)`
  ${({ theme }) => `
  font-family: Roboto Bold;

  

  ${theme.breakpoints.up('md')} {
   font-size: 1.4em;
   margin-top: 1.5em;
   line-height: 50px;
   order: initial;
  }
  ${theme.breakpoints.down('md')} {
   font-size: 1.4em;
   margin: 0 0 1.0em 0;
   text-align: left;
  }
  `}
` as StyledPropsFix;

export const Subtitle = styled(Typography)`
  ${({ theme }) => `
  font-family: Roboto Bold;

  ${theme.breakpoints.up('md')} {
   font-size: 2.1em;

   margin: 0.5em 0;
   line-height: 1.45em;
   order: initial;
  }
  ${theme.breakpoints.down('md')} {
   font-size: 1.6em;
   text-align: left;
  }

  `}
` as StyledPropsFix;

export const InfoText = styled(Typography)`
  ${({ theme }) => `
  font-family: Roboto Regular;
  padding-bottom: 1.6em;
  color: ${theme.palette.text.primary};

  ${theme.breakpoints.up('md')} {
    font-size: 1.1em;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 1.0em;
    text-align: left;
  }
`}
` as StyledPropsFix;

export const InfoBox = styled(Box)`
  ${({ theme }) => `
  align-self: start;

  ${theme.breakpoints.up('md')} {
   display: inline-flex;
   flex-direction: column;
   justify-content: center;
   align-self: auto;
   order: initial;
   flex: 10;
  }
  ${theme.breakpoints.down('md')} {
   flex-direction: column;
   display: flex;
   order: 4;
   flex: unset;
  }
`}
`;

export const ContentContainer = styled(Container)`
  ${({ theme }) => `
  height: 100;
  position: relative;

  ${theme.breakpoints.up('md')} {
    display: flex;
    margin: 1em auto;
    gap: 3.5em;
    align-items: initial;
    max-width: 100%;
  }
  ${theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`}
`;

export const ContentBox = styled(Box)`
  ${({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${theme.palette.background.paper}
  `}
`;

export const SideBox = styled(Box)`
  ${({ theme }) => `

  ${theme.breakpoints.up('md')} {
   display: inline-block;
   position: relative;
   width: 93em;
   flex: 10;
  }

  ${theme.breakpoints.down('md')} {
   display: flex;
   position: relative;
   width: 100%;
   height: 4em;
   flex: unset;
  }
`}
`;
export const MidBox = styled(Box)`
  ${({ theme }) => `

  ${theme.breakpoints.up('md')} {
   text-align: center;
   position: relative;
   width: 36px;
   order: initial;
   flex: 1;
  }

  ${theme.breakpoints.down('md')} {
   display: flex;
   position: relative;
   width: 100%;
   height: 36px;
   order: 1;
   margin-bottom: 1em;
   flex: unset;
  }
`}
`;

export const DashedLine = styled(Box)`
  ${({ theme }) => `

  ${theme.breakpoints.up('md')} {
    position: absolute;
    left: 50%;
    height: 69%;
    width: calc(1% + 2px);
    background-image: linear-gradient(to bottom, #DADADA 50%, transparent 50%);
    background-size: 1px 18px;
    margin: 3em auto;
  }  
  ${theme.breakpoints.down('md')} {
    position: absolute;
    top: 50%;
    width: 85%;
    height: calc(1% + 2px);
    background-image: linear-gradient(to right, #DADADA 50%, transparent 50%);
    background-size: 14px 1px;
    margin: auto 3.5em;
  }
`}
`;
export const CircleMilestone = styled(Box)`
  ${({ theme }) => `
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: inline-block;

  ${theme.breakpoints.up('md')} {
    margin-top: 2.6em;
  }
  
  ${theme.breakpoints.down('md')} {
    margin: 0;
  }
`}
`;
